.leaflet-container {
  height: 100%;
  width: 100%;
}

.ward-valid {
  background-color: #b2df8a;
}

.ward-toobig {
  background-color: #fb9a99;
}

.ward-toosmall {
  background-color: #fdbf6f;
}

.ward-noncontig {
  background-color: #cab2d6;
}

.da-winner {
  background-color: #a6cee3
}